import React, { useEffect, useState } from "react";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentNotification } from "../../../store/actions";
import moment from "moment";
import { setUnreadNotify } from "../../../store/slices/modalSlice";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";

const sortsLists = [
  {
    name: (
      <p>
        All<span className="hidden sm:inline"> Notifications</span>
      </p>
    ),
  },
  {
    name: (
      <p>
        Invoice Email<span className="hidden sm:inline"> Notifications</span>
      </p>
    ),
  },
  {
    name: (
      <p>
        Collection Email<span className="hidden sm:inline"> Notifications</span>
      </p>
    ),
  },
  {
    name: (
      <p>
        Subscription Create
        <span className="hidden sm:inline"> Notifications</span>
      </p>
    ),
  },
  {
    name: (
      <p>
        Fund Deposit<span className="hidden sm:inline"> Notifications</span>
      </p>
    ),
  },
  {
    name: (
      <p>
        Bill Payment<span className="hidden sm:inline"> Notifications</span>
      </p>
    ),
  },
  {
    name: (
      <p>
        Mobile Service Purchase
        <span className="hidden sm:inline"> Notifications</span>
      </p>
    ),
  },
  {
    name: (
      <p>
        Profile Information Update
        <span className="hidden sm:inline"> Notifications</span>
      </p>
    ),
  },
  {
    name: (
      <p>
        General Information
        <span className="hidden sm:inline"> Notifications</span>
      </p>
    ),
  },
];

function Notifications() {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState();
  const [groupedNotify, setGroupedNotify] = useState({});
  const [selected, setSelected] = useState(sortsLists[0]);

  const { paymentNotify } = useSelector((state) => state.cart);

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getPaymentNotification())
        .unwrap()
        .then((res) => {
          const unreadNotifyAmount = res?.length - notifications?.length;
          if (unreadNotifyAmount > 0) {
            dispatch(setUnreadNotify(unreadNotifyAmount));
          }
        })
        .catch((err) => err);
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [dispatch, notifications]);

  useEffect(() => {
    if (notifications && notifications?.length) {
      const groupByCategory =
        notifications &&
        notifications?.length &&
        notifications?.reduce((group, product) => {
          const { notification_date } = product;
          const formated = moment(notification_date).format("LL");
          group[formated] = group[formated] ?? [];
          group[formated].push(product);
          return group;
        }, {});

      setGroupedNotify(groupByCategory);
    }
  }, [notifications]);

  useEffect(() => {
    try {
      if (selected?.name?.props?.children[0] && notifications?.length) {
        switch (selected?.name?.props?.children[0]) {
          case "All":
            const groupByCategory =
              notifications &&
              notifications?.length &&
              notifications?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategory);
            break;
          case "Invoice Email":
            const invoiceEmails = notifications?.filter(
              (notify) => notify?.notification_category_id === 1
            );

            const groupByCategoryInvoice =
              invoiceEmails &&
              invoiceEmails?.length &&
              invoiceEmails?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategoryInvoice);

            break;
          case "Collection Email":
            const collectionEmails = notifications?.filter(
              (notify) => notify?.notification_category_id === 2
            );

            const groupByCategoryCollection =
              collectionEmails &&
              collectionEmails?.length &&
              collectionEmails?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategoryCollection);
            break;
          case "Subscription Create":
            const subscriptionCreate = notifications?.filter(
              (notify) => notify?.notification_category_id === 3
            );

            const groupByCategorySubscription =
              subscriptionCreate &&
              subscriptionCreate?.length &&
              subscriptionCreate?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategorySubscription);
            break;
          case "Fund Deposit":
            const fundDeposit = notifications?.filter(
              (notify) => notify?.notification_category_id === 4
            );

            const groupByCategoryFund =
              fundDeposit &&
              fundDeposit?.length &&
              fundDeposit?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategoryFund);
            break;
          case "Bill Payment":
            const billPayment = notifications?.filter(
              (notify) => notify?.notification_category_id === 5
            );

            const groupByCategoryBill =
              billPayment &&
              billPayment?.length &&
              billPayment?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategoryBill);
            break;
          case "Mobile Service Purchase":
            const mobileServicePurchase = notifications?.filter(
              (notify) => notify?.notification_category_id === 6
            );

            const groupByCategoryMobile =
              mobileServicePurchase &&
              mobileServicePurchase?.length &&
              mobileServicePurchase?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategoryMobile);
            break;
          case "Profile Information Update":
            const profileInfo = notifications?.filter(
              (notify) => notify?.notification_category_id === 7
            );

            const groupByCategoryProfile =
              profileInfo &&
              profileInfo?.length &&
              profileInfo?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategoryProfile);
            break;
          case "General Information":
            const generalInfo = notifications?.filter(
              (notify) => notify?.notification_category_id === 8
            );

            const groupByCategoryGeneral =
              generalInfo &&
              generalInfo?.length &&
              generalInfo?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategoryGeneral);
            break;
          default:
            const groupByCategoryAll =
              notifications &&
              notifications?.length &&
              notifications?.reduce((group, product) => {
                const { notification_date } = product;
                const formated = moment(notification_date).format("LL");
                group[formated] = group[formated] ?? [];
                group[formated].push(product);
                return group;
              }, {});

            setGroupedNotify(groupByCategoryAll);
        }
      }
    } catch (err) {
      return err;
    }
  }, [selected?.name?.props?.children, notifications]);

  useEffect(() => {
    if (!paymentNotify) {
      dispatch(getPaymentNotification());
    }
  }, [paymentNotify, dispatch]);

  useEffect(() => {
    if (paymentNotify) {
      setNotifications(paymentNotify);
    }
  }, [paymentNotify]);

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="mx-auto max-w-[1600px] bg-white px-3 pt-14 text-xs xsm:px-4 sm:px-6 sm:text-sm md:px-12 lg:px-14">
        <div className="flex flex-row items-start justify-between">
          <div className="text-xl font-bold text-gray-600 sm:text-2xl">
            Notifications
          </div>
          <div className="flex items-baseline justify-end space-x-1 xsm:space-x-2">
            <div className="text-lg font-medium text-gray-600">Sort:</div>
            <div className="">
              <Listbox value={selected} onChange={setSelected}>
                <div className="relative mt-1">
                  <div className="flex items-center space-x-1 xsm:space-x-2">
                    <div className="block truncate text-sm font-semibold text-gray-500">
                      {selected.name}
                    </div>
                    <Listbox.Button className="relative focus:outline-none">
                      <div className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
                        <FiChevronDown
                          className="h-4 w-4 text-olivine-500"
                          aria-hidden="true"
                        />
                      </div>
                    </Listbox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute right-0 z-50 mt-1 w-32 overflow-auto rounded-lg bg-white py-1 text-base shadow-md shadow-gray-300 ring-opacity-5 focus:outline-none xsm:w-64 sm:w-64 sm:text-sm">
                      {sortsLists.map((sortsList, sortsListIdx) => (
                        <Listbox.Option
                          key={sortsListIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-3 pl-10 pr-4 ${
                              active
                                ? "bg-[#F5F5F5] font-semibold text-neutral-500"
                                : "font-semibold text-gray-700"
                            }`
                          }
                          value={sortsList}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {sortsList.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-olivine-500">
                                  <AiOutlineCheck
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>
        <div className="mt-4 w-full max-w-2xl pb-40">
          {groupedNotify && Object.keys(groupedNotify).length
            ? Object.keys(groupedNotify).map((k) => (
                <>
                  <div className="mt-6 flex flex-row items-center justify-center space-x-1 text-sm font-light text-gray-700">
                    <span>{k}</span>
                    {/* <GoDotFill className="h-2 w-2 text-gray-600" />
                    <span>12:09 pm</span> */}
                  </div>
                  {groupedNotify[k]?.length
                    ? groupedNotify[k].map((item) => (
                        <div className="mt-5 flex w-full flex-row items-start space-x-2 rounded-md border-[0.5px] border-gray-200 p-4 text-gray-600 shadow-xl shadow-gray-200">
                          <GoDotFill className="h-5 w-5 text-olivine-600" />
                          <div className="space-y-1">
                            <div className="flex w-full flex-row justify-between">
                              <h3 className="font-bold">
                                {item?.notification_subject}
                              </h3>
                              <p className="text-xs italic">
                                {item?.notification_category_name}
                              </p>
                            </div>

                            <p className="text-xs">
                              {item?.notification_message}
                            </p>
                          </div>
                        </div>
                      ))
                    : null}
                </>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
