import React, { useState } from "react";
import SignedInNavbarImproved from "../SignedInNavbarImproved";
import DisclosureVoucherComponent from "./DisclosureVoucherComponent";
import { Modal, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RedeemAVoucher from "./redeemVoucher/RedeemAVoucher";
import BuyAGiftVoucherPin from "./buyAGiftVoucher/BuyAGiftVoucherPin";
import RedeemAvoucherSuccess from "./redeemVoucher/RedeemAvoucherSuccess";
import PurchaseBulkGiftVoucherOne from "./bulkVoucher/PurchaseBulkGiftVoucherOne";
import VoucherSummary from "./bulkVoucher/VoucherSummary";
import { authService } from "../../services/authService";
import BuyAGiftVoucher from "./buyAGiftVoucher/BuyAGiftVoucher";

function Voucher() {
  const [open, setOpen] = useState(false);
  const [openVoucherSummary, setOpenVoucherSummary] = useState(false);
  const [openPin, setOpenPin] = useState(false);
  const [openRedeem, setOpenRedeem] = useState(false);
  const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false);
  const [voucherDetails, setVoucherDetails] = useState({});
  const [voucherSummaryItems, setVoucherSummaryItems] = useState({});
  const [nextAction, setNextAction] = useState("");
  const corporateId = authService.getCustomerCorporateId();
  const [isBulk, setIsBulk] = useState(false);
  const corporateAdminStatusId =
    authService.getCustomerCorporateAdminStatusId();

  let navigate = useNavigate();

  const handleItemPull = (formValues, action) => {
    if (formValues) {
      setVoucherSummaryItems(formValues);
      setNextAction(action);
    }
  };

  return (
    <div>
      <SignedInNavbarImproved />
      <div className="mx-auto mt-10 max-w-[1600px] px-3 pb-40 xsm:px-6 sm:px-8 md:px-12 lg:px-14">
        <div className="flex flex-row items-center justify-between">
          <h1 className="text-3xl font-bold text-gray-700">Voucher</h1>
          <p
            onClick={() => navigate("/voucher-history")}
            className="cursor-pointer text-lg font-semibold text-olivine-500 hover:underline hover:underline-offset-4"
          >
            History
          </p>
        </div>
        <div className="mt-6 rounded-lg p-4 shadow-lg sm:p-6">
          <h2 className="text-lg font-semibold text-gray-700">
            Gifting a Voucher
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            "Gift a voucher" feature on Grocedy allows you to purchase a voucher
            using your available funds in your wallet and send it to someone
            else. The recipient can then redeem the voucher to make purchases on
            our platform, pay bills, buy airtime, or data.
          </p>
          <div className="mt-6 space-x-4">
            <button
              onClick={() => setOpen(true)}
              className="transform cursor-pointer rounded-md bg-olivine-500 px-6 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 sm:px-10"
            >
              Create a Voucher
            </button>
            <button
              onClick={() => setOpenRedeem(true)}
              className="rounded-md border-[1px] border-olivine-500 px-6 py-[10px] text-sm text-olivine-500 transition-all duration-200 hover:border-olivine-400 hover:ring-[1px] hover:ring-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-100 sm:px-10"
            >
              Redeem a Voucher
            </button>
          </div>
        </div>
        <div className="mt-16">
          <h2 className="text-xl font-semibold text-gray-700">
            Frequently Asked Questions
          </h2>
          <div className="mt-4 space-y-4">
            <DisclosureVoucherComponent
              question={`What does the gift a voucher feature do?`}
              answer1={`The "Gift a voucher" feature offers a convenient way for you to surprise your loved ones with a voucher that can be used for various purposes. Whether it's for a birthday, special occasion, or just a thoughtful gesture, this feature is designed to make gifting easy and versatile on Grocedy.`}
            />
            <DisclosureVoucherComponent
              question={`How does  Grocedy Vouchers work?`}
              answer1={`How it works:`}
              answer2={`If you have funds in your Grocedy wallet, simply access the "Gift a voucher" feature from the Products page. Enter the amount for the voucher and provide the recipient's details, like their phone number, email.and a short message. Confirm the purchase, and we will create and send the voucher to them. They will receive a notification or email with the voucher details.`}
              answer3={`If you don't have enough funds in your wallet to purchase the voucher, don't worry! During the checkout process, you will be prompted to fund your wallet with the required amount. Once your wallet is funded, you can proceed to complete the voucher purchase as mentioned earlier.`}
            />
            <DisclosureVoucherComponent
              question={`How do i purchase a voucher ?`}
              answer1={`How to purchase a voucher: `}
              answer2={`To purchase a voucher for someone:`}
              answer3={`1. Log in to your Grocedy account.`}
              answer4={`2. Find the "Gift a voucher" feature, located in the "Product" section`}
              answer5={`3. Enter the amount you want for the voucher.`}
              answer6={`4. Enter the recipient's information, such as their phone number, email.and a short message.`}
              answer7={`5. Review the details and complete the payment process to confirm the purchase.`}
            />
            <DisclosureVoucherComponent
              question={`How do I redeem my Vouchers?`}
              answer1={`How to redeem: `}
              answer2={`If you receive a voucher, here's how to redeem it:`}
              answer3={`1. Log in to your Grocedy account or create one if you don't have an account yet.`}
              answer4={`2. Look for the voucher redemption section,  found in the wallet area.`}
              answer5={`3. Enter the voucher code.`}
              answer6={`4. Once the voucher is successfully redeemed, the funds will be added to your wallet.`}
              answer7={`5. You can now use the redeemed amount to purchase any products available on Grocedy or use it for bill payments, purchasing airtime, or data.`}
            />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-4 overflow-hidden px-2 xsm:px-4">
          <Box className=" mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            {corporateId !== 1 && corporateAdminStatusId === 1 ? (
              <PurchaseBulkGiftVoucherOne
                setOpen={setOpen}
                setOpenVoucherSummary={setOpenVoucherSummary}
                setOpenPin={setOpenPin}
                setVoucherDetails={setVoucherDetails}
                handleItemPull={handleItemPull}
                setIsBulk={setIsBulk}
              />
            ) : (
              <BuyAGiftVoucher
                setOpen={setOpen}
                setOpenPin={setOpenPin}
                setVoucherDetails={setVoucherDetails}
                setVoucherSummaryItems={setVoucherSummaryItems}
              />
            )}
          </Box>
        </div>
      </Modal>
      <Modal
        open={openPin}
        onClose={() => setOpenPin(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-4 overflow-hidden px-2 xsm:px-4">
          <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <BuyAGiftVoucherPin
              setOpenPin={setOpenPin}
              voucherDetails={voucherDetails}
              voucherSummaryItems={voucherSummaryItems}
              nextAction={nextAction}
              isBulk={isBulk}
            />
          </Box>
        </div>
      </Modal>
      <Modal
        open={openRedeem}
        onClose={() => setOpenRedeem(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-16 overflow-hidden px-2 xsm:px-4">
          <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <RedeemAVoucher
              setOpenRedeem={setOpenRedeem}
              setOpenRedeemSuccess={setOpenRedeemSuccess}
            />
          </Box>
        </div>
      </Modal>
      <Modal
        open={openRedeemSuccess}
        onClose={() => setOpenRedeemSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-16 overflow-hidden px-2 xsm:px-4">
          <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <RedeemAvoucherSuccess
              setOpenRedeemSuccess={setOpenRedeemSuccess}
            />
          </Box>
        </div>
      </Modal>
      <Modal
        open={openVoucherSummary}
        onClose={() => setOpenVoucherSummary(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-16 overflow-hidden px-2 xsm:px-4">
          <Box className="mx-auto max-w-lg rounded-md bg-[#FEFEFD] p-4 shadow shadow-gray-500">
            <VoucherSummary
              setOpenVoucherSummary={setOpenVoucherSummary}
              voucherSummaryItems={voucherSummaryItems}
              setOpenPin={setOpenPin}
            />
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default Voucher;
