import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../../services/authService";
import {
  bulkVoucherCreate,
  getTransactions,
  getWalletBalance,
  verifyTransactionPinCode,
  voucherCreate,
  voucherHistory,
} from "../../../store/actions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { resetWalletBalance } from "../../../store/slices/userSlice";
import { setUserNextAction } from "../../../store/slices/modalSlice";
import { resetUpdated } from "../../../store/slices/voucherSlice";

function BuyAGiftVoucherPin({
  setOpenPin,
  voucherDetails,
  voucherSummaryItems,
  nextAction,
  isBulk,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [fetchedUserBalance, setFetchedUserBalance] = useState();

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();

  const { walletBalance } = useSelector((state) => state.user);

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data));
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [dispatch, userId, customerId, verification_code, walletBalance]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const toastId = toast.loading("Creating voucher");

      if (voucherDetails?.amount > walletBalance?.available_balance) {
        return toast.error(
          `Insufficient balance, you have ₦${Number(
            fetchedUserBalance?.available_balance
          ).toLocaleString()}`,
          {
            id: toastId,
          }
        );
      }

      setIsLoading(true);

      const data = {
        ...voucherDetails,
        transaction_pin: otp,
        customer_id: customerId?.toString(),
        user_id: userId?.toString(),
      };

      dispatch(voucherCreate(data))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            setIsLoading(false);
            return toast.error(res.response?.error, {
              id: toastId,
            });
          }
          if (res.result_status[0].message_id !== 1) {
            setIsLoading(false);
            return toast.error(res.result_status[0].message_text, {
              id: toastId,
            });
          }

          dispatch(resetWalletBalance());
          const id = customerId?.toString();
          dispatch(getTransactions(id));

          toast.success(res.result_status[0].message_text, {
            id: toastId,
          });

          dispatch(getTransactions(id));
          const data = {
            customer_id: customerId?.toString(),
          };

          dispatch(voucherHistory(data));
          setOpenPin(false);
          setIsLoading(false);
          return navigate("/voucher/createVoucherSuccess");
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleSubmitBulk = (e) => {
    e.preventDefault();
    try {
      if (voucherSummaryItems.totalAmount > walletBalance?.available_balance) {
        return toast.error(
          `Insufficient balance, you have ₦${Number(
            fetchedUserBalance?.available_balance
          ).toLocaleString()}`
        );
      }

      const verifyData = {
        sql_ref_no: "41",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        pin_number: otp,
        option: "1",
      };

      setIsLoading(true);

      dispatch(verifyTransactionPinCode(verifyData))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            setIsLoading(false);
            return toast.error("Pin Incorrect...");
          }

          const toastId = toast.loading("Creating voucher");

          const data = {
            customer_id: customerId?.toString(),
            quantity: voucherSummaryItems.quantity,
            amount: voucherSummaryItems.totalAmount.toString(),
            remark: voucherSummaryItems.remark,
          };
          dispatch(bulkVoucherCreate(data))
            .unwrap()
            .then((res) => {
              if (res.status === 403) {
                setIsLoading(false);
                return toast.error(res.response.error, {
                  id: toastId,
                });
              }

              if (res.result_status[0].message_id !== 1) {
                setIsLoading(false);
                return toast.error(res.result_status[0].message_text, {
                  id: toastId,
                });
              }

              dispatch(resetWalletBalance());
              const id = customerId?.toString();
              dispatch(getTransactions(id));
              dispatch(resetUpdated());

              toast.success(res.result_status[0].message_text, {
                id: toastId,
              });
              dispatch(setUserNextAction(nextAction));

              const data = {
                customer_id: customerId?.toString(),
              };

              dispatch(voucherHistory(data));

              setOpenPin(false);
              setIsLoading(false);
              return navigate("/voucher/createVoucherBulkSuccess");
            })
            .catch((err) => err);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="max-w-lg px-4 py-3">
      <div className="flex max-w-lg flex-row items-center justify-between bg-[#FEFEFD]">
        <h2 className="text-lg font-semibold text-gray-700">Pay with Wallet</h2>
        <div
          onClick={() => setOpenPin(false)}
          className="cursor-pointer rounded-full bg-olivine-500 p-1 text-white active:bg-olivine-400"
        >
          <IoClose className="h-5 w-5" />
        </div>
      </div>
      <div className="mt-4 rounded-lg border-[0.5px] border-gray-200 bg-[#EAF6DC] px-4 py-2 sm:px-6">
        <div className="flex items-center justify-center space-x-2 sm:justify-start">
          <div>
            <FaWallet className="h-5 w-5" />
          </div>
          <div className="">Total Balance</div>
          {/* <div className="text-olivine-500 transition duration-500 hover:text-olivine-600 active:rotate-45">
            <BiRefresh className="h-6 w-6 cursor-pointer" />
          </div> */}
        </div>
        <div className="mt-1 flex items-center justify-center space-x-1 xsm:mt-0 sm:justify-start">
          {enabled ? (
            <div className="h-10 text-2xl font-bold text-gravel-600 sm:text-3xl">
              {fetchedUserBalance && fetchedUserBalance?.available_balance
                ? `₦ ${Number(
                    fetchedUserBalance?.available_balance
                  ).toLocaleString()}`
                : "₦ 0.00"}
            </div>
          ) : (
            <div className="grid h-10 pt-1 text-2xl font-bold text-gravel-600 sm:text-3xl">
              ***********
            </div>
          )}

          <div className="flex items-center justify-center">
            <div className="text-[#828282] xsm:flex xsm:items-center">
              {enabled ? (
                <div
                  onClick={() => setEnabled(false)}
                  className="flex h-10 w-6 items-center justify-center pb-1 xsm:pb-0"
                >
                  <AiFillEyeInvisible className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                </div>
              ) : (
                <div
                  onClick={() => setEnabled(true)}
                  className="flex h-10 w-6 items-center justify-center pb-1 xsm:pb-0"
                >
                  <AiFillEye className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 rounded-lg border-[0.5px] border-gray-200 bg-white py-2 text-sm text-gray-600">
        <div className="px-4 sm:px-6">
          <p className="text-center">Amount</p>
          <p className="mt-1 text-center text-lg font-semibold">
            {voucherDetails && voucherDetails?.amount && !isBulk
              ? `₦ ${Number(voucherDetails?.amount).toLocaleString()}`
              : null}
            {voucherSummaryItems && voucherSummaryItems?.totalAmount && isBulk
              ? `₦ ${Number(voucherSummaryItems?.totalAmount).toLocaleString()}`
              : null}
          </p>
        </div>
        <div className="mt-2 flex flex-row justify-between border-[0.5px] border-gray-200 px-4 py-3 sm:px-6">
          <p>For</p>
          <p>Gift Voucher</p>
        </div>
        <div className="flex flex-row justify-between px-4 pt-3 sm:px-6">
          <p>From:</p>
          <p>Wallet</p>
        </div>
      </div>
      <div className="mt-4 rounded-lg border-[0.5px] border-gray-200 bg-white px-4 py-2 shadow-sm sm:px-6">
        <p className="text-center text-sm font-medium text-gray-600">
          Please input your transaction PIN
        </p>
        <div className="mt-4 flex justify-center">
          <OtpInput
            inputStyle={{
              margin: 2,
              height: 34,
              width: 34,
              borderRadius: 4,
              borderColor: "#BDBDBD",
              outline: "none",
              border: "1px solid #BDBDBD",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
          />
        </div>
      </div>
      <div className="mt-6">
        {voucherSummaryItems && voucherSummaryItems?.totalAmount ? (
          !isLoading ? (
            <button
              disabled={otp?.length === 4 ? false : true}
              onClick={(e) => handleSubmitBulk(e)}
              className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-16 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500"
            >
              Confirm
            </button>
          ) : (
            <button
              disabled={true}
              className="w-full transform cursor-not-allowed rounded-md bg-olivine-500 px-16 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500"
            >
              loading...
            </button>
          )
        ) : !isLoading ? (
          <button
            disabled={otp?.length === 4 ? false : true}
            onClick={(e) => handleSubmit(e)}
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-16 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500"
          >
            Confirm
          </button>
        ) : (
          <button
            disabled={true}
            className="w-full transform cursor-not-allowed rounded-md bg-olivine-500 px-16 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500"
          >
            loading...
          </button>
        )}
      </div>
    </div>
  );
}

export default BuyAGiftVoucherPin;
