import React, { useContext, useEffect, useState } from "react";
import SignedInNavbarImproved from "./SignedInNavbarImproved";
import SignedInPlansPageCards from "./SignedInPlansPageCards";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import { setRate } from "../store/slices/generalSlice";
import IsLoadingOne from "./IsLoading_One";
import { authService } from "../services/authService";
import { setIsGifting } from "../store/slices/modalSlice";
import { productService } from "../services/productService";
import RxjsPlanContext from "../context/RxjsPlanContext";

function SignedInProductPlanPage() {
  const dispatch = useDispatch();
  const params = useParams();
  let navigate = useNavigate();
  const PlanContext = useContext(RxjsPlanContext);
  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [fetchedImages, setFetchedImages] = useState([]);
  const [fetchedPlansUsd, setFetchedPlansUsd] = useState([]);

  const [usdPlan, setUsdPlan] = useState({});
  const [isShowingNGR, setIsShowingNGR] = useState(true);
  const [isShowingUSD, setIsShowingUSD] = useState(false);

  const { loading } = useSelector((state) => state.error);

  const token = authService.getToken();
  const customerId = authService.getCurrentCustomerId();

  useEffect(() => {
    const customer_id = customerId ? customerId?.toString() : "0";

    PlanContext.getAllProductPlan().subscribe((allPlanItems) => {
      if (allPlanItems?.plan_info?.length) {
        const filteredPlans = allPlanItems?.plan_info.filter(
          (plan) => plan?.product_id == params.id
        );

        setFetchedPlans(filteredPlans);
        setFetchedPlansUsd(allPlanItems?.currency_info);

        const filteredImages = allPlanItems?.Plan_Image?.filter(
          (image) => image?.product_id === params.id
        );

        setFetchedImages(filteredImages);
      } else {
        //  Fetch new product Plan
        productService
          .getGrocedyForAllProductList(customer_id)
          .then((response) => {
            const allPlanItems = response?.data;
            if (allPlanItems?.plan_info?.length) {
              PlanContext.setAllProductPlanList(allPlanItems); // save to storage
              const filteredPlans = allPlanItems?.plan_info.filter(
                (plan) => plan?.product_id == params.id
              );
              setFetchedPlans(filteredPlans);
              setFetchedPlansUsd(allPlanItems?.currency_info);

              const filteredImages = allPlanItems?.Plan_Image?.filter(
                (image) => image?.product_id === params.id
              );
              setFetchedImages(filteredImages);
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    if (fetchedPlansUsd.length) {
      const usdConvert = fetchedPlansUsd?.filter(
        (fpu) => fpu.currency_code === "USD"
      );
      setUsdPlan(usdConvert);
    }
  }, [fetchedPlansUsd]);

  useEffect(() => {
    if (usdPlan && isShowingUSD) {
      dispatch(setRate(usdPlan));
    }

    if (isShowingNGR) dispatch(setRate(""));
  }, []);

  const handleSwitch = (currency) => {
    switch (currency) {
      case "NGR":
        setIsShowingUSD(false);
        setIsShowingNGR(true);
        break;
      case "USD":
        setIsShowingNGR(false);
        setIsShowingUSD(true);
        break;
      default:
        return;
    }
  };

  const handleView = (location) => {
    dispatch(setIsGifting(false));
    switch (location) {
      case "GROBP":
        return navigate(`/home/plan/${location}`);
      case "GROSP":
        return navigate(`/home/plan/${location}`);
      case "GROGP":
        return navigate(`/home/plan/${location}`);
      case "GRODP":
        return navigate(`/home/plan/${location}`);
      case "GROPP":
        return navigate(`/home/plan/${location}`);
      case "home":
        return navigate("/home");
      default:
        return;
    }
  };

  const goToCartFunction = (id) => {
    navigate(`/home/cart/${id}`);
  };

  return (
    <>
      {loading ? (
        <IsLoadingOne message="loading..." />
      ) : (
        <div>
          {token ? <SignedInNavbarImproved /> : <Navbar />}
          <div className="px-4 py-16 sm:px-8 md:px-16">
            <div className="text-3xl font-bold text-gray-600">Plans</div>
            {/* <div className="mt-6 flex cursor-pointer font-sans font-medium">
              <div
                onClick={() => handleSwitch("NGR")}
                className={
                  isShowingNGR
                    ? "rounded-l-md bg-olivine-500 px-4 py-3 text-white"
                    : "rounded-l-md bg-caper-500 px-4 py-3 text-gravel-500"
                }
              >
                NGN
              </div>
              <div
                onClick={() => handleSwitch("USD")}
                className={
                  !isShowingUSD
                    ? "rounded-r-md bg-caper-500 px-4 py-3 text-gravel-500"
                    : "rounded-r-md bg-olivine-500 px-4 py-3 text-white"
                }
              >
                USD
              </div>
            </div> */}
            <div className="mt-8 grid gap-5 grid-auto-fit-sm xsm:grid-auto-fit-md">
              {fetchedPlans &&
                isShowingNGR &&
                !isShowingUSD &&
                fetchedPlans?.map((plan, idx) => (
                  <SignedInPlansPageCards
                    key={idx}
                    clicked={() => handleView(plan.plan_code)}
                    title={plan.name}
                    plan_id={plan.plan_id}
                    price={plan.recurring_price}
                    quantity={plan.description}
                    goToCart={() => goToCartFunction(plan.plan_code)}
                    images={fetchedImages}
                  />
                ))}

              {fetchedPlans &&
                !isShowingNGR &&
                isShowingUSD &&
                fetchedPlans?.map((plan, idx) => (
                  <SignedInPlansPageCards
                    key={idx}
                    clicked={() => handleView(plan.plan_code)}
                    title={plan.name}
                    plan_id={plan.plan_id}
                    price={plan.recurring_price}
                    quantity={plan.description}
                    rate={usdPlan[0]?.exchange_rate}
                    goToCart={() => goToCartFunction(plan.plan_code)}
                    images={fetchedImages}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SignedInProductPlanPage;
