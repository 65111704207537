import React, { useEffect, useState } from "react";

function SignedInPlansPageCards({
  title,
  price,
  quantity,
  clicked,
  rate,
  goToCart,
  images,
  plan_id,
}) {
  const [planImage, setPlanImage] = useState([]);

  useEffect(() => {
    if (images?.length && plan_id) {
      const planImages = images?.filter((img) => img?.plan_id === plan_id);

      setPlanImage(planImages);
    }
  }, [plan_id, images]);

  return (
    <div className="mb-6 rounded-md border-[1px] border-gray-300 px-4 py-4 sm:max-w-md">
      <div className="text-xl font-bold text-gravel-600">{title}</div>
      <div className="mt-1 rounded-md border border-gray-200">
        <img
          src={planImage && planImage?.length && planImage[0]?.plan_image_url}
          className="aspect-auto h-40 w-full rounded-md object-cover xsm:h-48 sm:h-40"
          alt=""
        />
      </div>
      <div className="mt-4 text-base font-bold text-gravel-500">
        {/* ₦{price}/month */}
        {rate
          ? `$${Number(price * +rate).toFixed(2)}/month`
          : `₦${Number(price).toLocaleString()}/month`}
      </div>
      <div className="mt-1 text-xs text-gravel-500">
        {/* Up to {quantity} items */}
        {quantity}
      </div>
      <div className="mt-3 flex items-center justify-between text-sm font-semibold text-gravel-500">
        <button
          onClick={goToCart ? goToCart : null}
          className="font-semibold hover:text-gravel-400"
        >
          ADD TO CART
        </button>
        <button
          onClick={clicked ? clicked : null}
          className="font-semibold hover:text-gravel-400 hover:underline"
        >
          VIEW
        </button>
      </div>
    </div>
  );
}

export default SignedInPlansPageCards;
