import React, { useState, useContext, useEffect } from "react";
import Logo from "../../../assets/logo.svg";
import Illustration from "../../../assets/Illustration.svg";
import { MdEmail } from "react-icons/md";
import { IoMdLock } from "react-icons/io";
import { BsFillPersonFill, BsFillTelephoneFill } from "react-icons/bs";
import { useNavigate, Link } from "react-router-dom";
import IsLoadingOne from "../../IsLoading_One";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { authService } from "../../../services/authService";
import LogicContext from "../../../context/LogicContext";

import FacebookImage from "../../../assets/facebookImage.svg";
import GoogleLogoImage from "../../../assets/googleLogoImage.svg";
import AppleImage from "../../../assets/appleImage.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import { googleLogin } from "../../../core/lib/googleLogin";
import { useDispatch } from "react-redux";
import {
  setIsCorporate,
  setIsFromLogin,
} from "../../../store/slices/modalSlice";

function RegisterForm({ corporate_option, corValue, secKey }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState("password");
  const logicContext = useContext(LogicContext);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(setIsFromLogin(false));
  }, []);

  useEffect(() => {
    setValue("corporate_code", corValue);
  }, [corValue]);

  const viewerPassword = () => {
    switch (viewPassword) {
      case "password":
        setViewPassword("text");
        break;
      case "text":
        setViewPassword("password");
        break;

      default:
        break;
    }
  };

  function onErrors(error) {
    toast.error("One or more value is required", "error");
  }

  const submitRegistrationForm = async () => {
    const formData = getValues();
    if (formData?.user_password !== formData?.user_password_confirm) {
      return toast.error("confirm password not match!");
    }

    let phoneNumber = formData?.user_phone_number;

    delete formData.user_password_confirm;
    formData["user_password"] = formData?.user_password?.replace(/#/g, "%23"); // Replace # in password to Unicode %23

    if (Array.from(phoneNumber)[0] === "0") {
      phoneNumber = "234" + phoneNumber.slice(1);
    }
    if (!formData?.corporate_code) {
      formData.corporate_code = corporate_option;
    }

    if (corporate_option === "CORPORATION") {
      dispatch(setIsCorporate(true));
    }

    if (secKey) {
      // for corporate user only (third party)

      try {
        const payload = {
          sql_ref_no: "5",
          ...formData,
          user_phone_number: phoneNumber,
          registration_channel: "2",
          corporate_key: secKey,
          option: "4",
        };
        setIsLoading(true);

        authService.registerUser(payload).then((apiResponse) => {
          setIsLoading(false);
          localStorage.setItem("isTour", true);
          localStorage.setItem("isTour_basket", true);
          localStorage.setItem("isTour_gropool", true);

          const filteredData = logicContext.httpResponseManager(
            apiResponse?.data
          );
          if (filteredData?.access_token) {
            authService.setTokenUnVerified(filteredData?.access_token);
            setTimeout(() => {
              navigate("/password/code");
            }, 1000);
          }
        });
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      // If no security key

      try {
        const payload = {
          sql_ref_no: "5",
          ...formData,
          user_phone_number: phoneNumber,
          registration_channel: "2",
          option: "1",
        };
        setIsLoading(true);

        authService.registerUser(payload).then((apiResponse) => {
          setIsLoading(false);
          localStorage.setItem("isTour", true);
          localStorage.setItem("isTour_basket", true);
          localStorage.setItem("isTour_gropool", true);

          const filteredData = logicContext.httpResponseManager(
            apiResponse?.data
          );
          if (filteredData?.access_token) {
            authService.setTokenUnVerified(filteredData?.access_token);
            setTimeout(() => {
              navigate("/password/code");
            }, 1000);
          }
        });
      } catch (err) {
        setIsLoading(false);
      }

      // end security usage
    }
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const onSuccess = async (res) => {
    setIsLoading(true);
    var accessToken = gapi?.auth?.getToken();

    const tokens = await googleLogin(
      accessToken,
      res.profileObj.givenName,
      res.profileObj.familyName
    );

    if (tokens.access_token) {
      authService.setToken(tokens.access_token);
      localStorage.setItem("token", tokens.access_token);

      localStorage.setItem("isTour", true);
      localStorage.setItem("isTour_basket", true);
      localStorage.setItem("isTour_gropool", true);

      toast.success("Welcome to Grocedy");

      navigate("/home/todo");
    } else {
      setIsLoading(false);
      toast.error("Something Went Wrong!");
    }
  };

  const onFailure = (res) => {
    return res;
  };

  const facebookResponseHandler = async (response) => {
    if (response?.userID) {
      // setIsLoading(true);
      let nameSplit = response?.name.split(" ");
      const userData = {
        surname: nameSplit[0],
        fname: nameSplit[1],
        email: response?.email,
        ssi_api_code: response?.data_access_expiration_time?.toString(),
      };
      await authService
        .facebookLogin(userData)
        .then((data) => {
          if (data?.access_token) {
            authService.setToken(data?.access_token);
            let tempUrl = localStorage.getItem("temp_url");
            localStorage.setItem("isTour", true);
            localStorage.setItem("isTour_basket", true);
            localStorage.setItem("isTour_gropool", true);
            if (tempUrl) {
              navigate(tempUrl);
              setTimeout(() => {
                localStorage.removeItem("temp_url");
              }, 2000);
            } else {
              navigate("/home/todo");
            }
          }
          // setIsLoading(false);
        })
        .catch((err) => {
          // setIsLoading(false);
          alert("Facebook login failed, please try again later.");
        });
    } else {
      toast.error("Facebook login failed!");
    }
  };

  return (
    <>
      {isLoading ? (
        <div>
          <IsLoadingOne message="signing you up..." />
        </div>
      ) : (
        <div className="sticky mx-auto h-screen w-full max-w-[1600px] md:grid md:grid-cols-9">
          <div className="mt-4 px-4 pb-8 xsm:px-8 sm:px-8 md:col-span-5 md:px-16 lg:pb-0">
            <Link to="/">
              <img
                src={Logo}
                className="aspect-auto h-11 w-12 flex-shrink-0 cursor-pointer object-cover transition duration-300 hover:opacity-80"
                alt=""
              />
            </Link>
            <div className="mt-4 sm:px-8 md:px-0">
              <div className="text-2xl font-bold text-gray-600">Register</div>
              <div className="mt-1 text-xs font-semibold text-gray-600">
                Enter your details to start using Grocedy
              </div>
            </div>
            <div className="mt-2 sm:px-8 md:px-0">
              <form onSubmit={handleSubmit(submitRegistrationForm, onErrors)}>
                <div className="items-start lg:flex lg:h-[75px] lg:justify-between lg:space-x-4">
                  <div className="lg:flex-1">
                    <label
                      htmlFor="firsName"
                      className="ml-3 text-xs text-gray-600"
                    >
                      First Name
                      <span className="ml-1 text-gray-400">*Required</span>
                    </label>
                    <div className="relative rounded-md">
                      <div className="inset-y-0 pl-3">
                        <BsFillPersonFill className="absolute mt-3 h-4 w-4 text-gray-600" />
                      </div>
                      <input
                        type="text"
                        placeholder="Enter your first name"
                        {...register("user_first_name", {
                          required: "FirstName is required!",
                        })}
                        className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-sm text-gray-600 placeholder:text-xs placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      <small className="text-red-600">
                        {errors.user_first_name?.message}
                      </small>
                    </div>
                  </div>
                  <div className="mt-3 lg:mt-0 lg:flex-1">
                    <label
                      htmlFor="lastName"
                      className="ml-3 text-xs text-gray-600"
                    >
                      Last Name
                      <span className="ml-1 text-gray-400">*Required</span>
                    </label>
                    <div className="relative rounded-md">
                      <div className="inset-y-0 pl-3">
                        <BsFillPersonFill className="absolute mt-3 h-4 w-4 text-gray-600" />
                      </div>
                      <input
                        type="text"
                        placeholder="Enter your last name"
                        {...register("user_last_name", {
                          required: "LastName is required!",
                        })}
                        className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-sm text-gray-600 placeholder:text-xs placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      <small className="text-red-600">
                        {errors.user_last_name?.message}
                      </small>
                    </div>
                  </div>
                </div>

                {/*  Custom Business */}
                {corporate_option === "BUSINESS" && (
                  <div className="mt-3 items-center lg:flex lg:justify-between lg:space-x-4">
                    <div className="lg:flex-1">
                      <label
                        htmlFor="firsName"
                        className="ml-3 text-xs text-gray-600"
                      >
                        Business Name
                        <span className="ml-1 text-gray-400">*Required</span>
                      </label>
                      <div className="relative rounded-md">
                        <div className="inset-y-0 pl-3">
                          <BsFillPersonFill className="absolute mt-3 h-4 w-4 text-gray-600" />
                        </div>
                        <input
                          type="text"
                          placeholder="Enter business name"
                          {...register("business_name", {
                            required: "Business Name is required!",
                          })}
                          className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-sm text-gray-600 placeholder:text-xs placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                        <small className="text-red-600">
                          {errors.business_name?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {/*  Custom Business */}
                {corporate_option === "CORPORATION" && (
                  <div className="mt-3 items-center lg:flex lg:justify-between lg:space-x-4">
                    <div className="lg:flex-1">
                      <label
                        htmlFor="firsName"
                        className="ml-3 text-xs text-gray-600"
                      >
                        Corporate Code
                        <span className="ml-1 text-gray-400">
                          *Please input the code provided by your corporation
                        </span>
                      </label>
                      <div className="relative rounded-md">
                        <div className="inset-y-0 pl-3">
                          <BsFillPersonFill className="absolute mt-3 h-4 w-4 text-gray-600" />
                        </div>
                        <input
                          type="text"
                          placeholder="Enter Corporate Code"
                          readOnly={corValue}
                          {...register("corporate_code", {
                            required: "Corporation code is required!",
                          })}
                          className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-sm text-gray-600 placeholder:text-xs placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                        <small className="text-red-600">
                          {errors.business_name?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {/* end custom */}
                <div className="mt-3 items-start lg:mt-4 lg:flex lg:h-20 lg:justify-between lg:space-x-4">
                  <div className="mt-3 lg:mt-0 lg:flex-1">
                    <label
                      htmlFor="lastName"
                      className="ml-3 text-xs text-gray-600"
                    >
                      Phone Number
                      <span className="ml-1 text-gray-400">*Required</span>
                    </label>
                    <div className="relative rounded-md">
                      <div className="inset-y-0 pl-3">
                        <BsFillTelephoneFill className="absolute mt-3 h-4 w-4 text-gray-600" />
                      </div>
                      <input
                        type="number"
                        placeholder="Enter your phone number"
                        {...register("user_phone_number", {
                          required: "Phone number is required!",
                        })}
                        maxLength="15"
                        className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-sm text-gray-600 placeholder:text-xs placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      <small className="text-red-600">
                        {errors.user_phone_number?.message}
                      </small>
                    </div>
                  </div>

                  <div className="mt-3 lg:mt-0 lg:flex-1">
                    <label
                      htmlFor="email"
                      className="ml-3 text-xs text-gray-500"
                    >
                      Email Address
                      <span className="ml-1 text-gray-400">*Required</span>
                    </label>
                    <div className="relative rounded-md">
                      <div className="inset-y-0 pl-3">
                        <MdEmail className="absolute mt-3 h-4 w-4 text-gray-600" />
                      </div>
                      <input
                        type="email"
                        placeholder="Enter your email address"
                        {...register("user_email_address", {
                          required: "Email is required!",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email address",
                          },
                        })}
                        className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-sm text-gray-600 placeholder:text-xs placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      <small className="text-red-600">
                        {errors.user_email_address?.message}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="items-start lg:flex lg:h-[75px] lg:justify-between lg:space-x-4">
                  <div className="lg:flex-1">
                    <label
                      htmlFor="referralCode"
                      className="ml-3 text-xs text-gray-600"
                    >
                      Refferal Code
                    </label>
                    <div className="relative rounded-md">
                      <div className="inset-y-0 pl-3">
                        <BsFillPersonFill className="absolute mt-3 h-4 w-4 text-gray-600" />
                      </div>
                      <input
                        type="text"
                        placeholder="Please Enter Refferal Code"
                        {...register("referral_code")}
                        className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-sm text-gray-600 placeholder:text-xs placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      <small className="text-red-600">
                        {errors.referral_code?.message}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="items-start lg:mt-2 lg:flex lg:h-24 lg:justify-between lg:space-x-4">
                  <div className="mt-3 lg:mt-0 lg:flex-1">
                    <label
                      htmlFor="password"
                      className="ml-3 text-xs text-gray-500"
                    >
                      Password
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <div className="absolute inset-y-0 flex justify-between pl-3">
                          <IoMdLock className=" mt-3 h-4 w-4 text-gravel-600" />
                        </div>
                        <input
                          type={viewPassword}
                          placeholder="Enter your password"
                          {...register("user_password", {
                            required: "Password is required!",
                            pattern: {
                              value:
                                /^(?=.*[_!@#$%^&*-])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])^.{8,}$/,
                              message:
                                "At lease 1 upper case, 1 lowercase, 1 special character and 1 number",
                            },
                            minLength: {
                              value: 8,
                              message: "Minimum value is 8 chars",
                            },
                          })}
                          className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-sm text-gray-600 placeholder:text-xs placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                        <small className="text-red-600">
                          {errors.user_password?.message}
                        </small>
                      </div>

                      <div
                        onClick={viewerPassword}
                        className="absolute right-3 top-2.5 flex-grow cursor-pointer text-xs font-semibold text-gray-500 transition duration-200 hover:text-olivine-500"
                      >
                        {viewPassword === "text" ? "Hide" : "View"}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 lg:mt-0 lg:flex-1">
                    <label
                      htmlFor="confirmPassword"
                      className="ml-3 text-xs text-gray-500"
                    >
                      Confirm Password
                    </label>
                    <div className="relative flex items-center justify-end rounded-md">
                      <div className="flex-grow">
                        <div className="absolute inset-y-0 flex justify-between pl-3">
                          <IoMdLock className=" mt-3 h-4 w-4 text-gravel-600" />
                        </div>
                        <input
                          type={viewPassword}
                          placeholder="Enter your password"
                          {...register("user_password_confirm", {
                            required: "Password is required!",
                            pattern: {
                              value:
                                /^(?=.*[_!@#$%^&*-])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])^.{8,}$/,
                              message:
                                "At lease 1 upper case, 1 lowercase, 1 special character and 1 number",
                            },
                            minLength: {
                              value: 8,
                              message: "Minimum value is 8 chars",
                            },
                          })}
                          className="block w-full rounded-md border-gray-400 bg-gray-100 pl-10 text-sm text-gray-600 placeholder:text-xs placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                        />
                        <small className="text-red-600">
                          {errors.user_password_confirm?.message}
                        </small>
                      </div>

                      <div
                        onClick={viewerPassword}
                        className="absolute right-3 top-2.5 flex-grow cursor-pointer text-xs font-semibold text-gray-500 transition duration-200 hover:text-olivine-500"
                      >
                        {viewPassword === "text" ? "Hide" : "View"}
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="mt-6 w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-2 active:bg-olivine-600"
                >
                  Create an Account
                </button>
              </form>
            </div>
            <div className="mt-3 pb-16 sm:px-8 md:px-0">
              <div className="text-xs text-gray-500">
                By clicking on{" "}
                <span className="font-semibold text-gray-500">
                  Create an Account
                </span>
                , I agree to the{" "}
                <Link to="/register/terms-and-conditions">
                  <span className="cursor-pointer font-bold transition duration-300 hover:text-gray-600 hover:underline hover:underline-offset-2">
                    Terms and Conditions
                  </span>
                </Link>{" "}
                of Grocedy
              </div>
              <div className="mt-2 text-sm text-gray-500">
                Already have an account?
                <span className="px-1 font-semibold text-olivine-500 transition duration-200 hover:text-olivine-500 hover:underline hover:underline-offset-2">
                  <Link to="/login">Login</Link>
                </span>
              </div>
              {corporate_option !== "CORPORATION" && (
                <>
                  <div className="mt-6 flex flex-row items-center space-x-4 px-2">
                    <div className="h-[0.5px] w-full flex-1 bg-gray-400" />
                    <div className="text-sm font-bold text-gray-500">OR</div>
                    <div className="h-[0.5px] w-full flex-1 bg-gray-400" />
                  </div>

                  <div className="mt-4 flex flex-col pb-20">
                    <FacebookLogin
                      appId={`${process.env.REACT_APP_FACEBOOK_CLIENT_ID}`}
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="public_profile,email,user_friends"
                      callback={facebookResponseHandler}
                      icon="fa-facebook"
                      render={(renderProps) => (
                        <div
                          onClick={renderProps.onClick}
                          className="ring-gray- flex cursor-pointer items-center justify-center space-x-4 rounded-lg bg-[#1877F2] p-3 text-olivine-500 ring-1 ring-[#1877F2] transition-all duration-300 hover:bg-opacity-80"
                        >
                          <img
                            src={FacebookImage}
                            alt="icon"
                            className="h-6 w-6"
                          />
                          <p className="text-sm font-medium text-white">
                            Sign up with Facebook
                          </p>
                        </div>
                      )}
                    />
                    <GoogleLogin
                      clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                      buttonText="Login with Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={"single_host_origin"}
                      render={(renderProps) => (
                        <div
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          className="mt-6 flex cursor-pointer items-center justify-center space-x-4 rounded-lg bg-white p-3 text-olivine-500 ring-1 ring-gray-300 transition-all duration-300 hover:bg-gray-100 active:bg-gray-200"
                        >
                          <img
                            src={GoogleLogoImage}
                            alt="icon"
                            className="h-6 w-6"
                          />
                          <p className="text-sm font-medium text-gray-600">
                            Sign up with Google
                          </p>
                        </div>
                      )}
                    />
                    <div className="mt-6 flex cursor-pointer items-center justify-center space-x-4 rounded-lg bg-black p-3 text-olivine-500 ring-1 ring-black transition-all duration-300 hover:bg-opacity-80">
                      <img src={AppleImage} alt="icon" className="h-6 w-6" />
                      <p className="text-sm font-medium text-white">
                        Sign up with Apple
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-span-4  hidden bg-olivine-500 md:grid">
            <div className="mx-auto grid h-[384px] w-[359px] justify-items-center pt-[130px] ">
              <img src={Illustration} className="h-72 w-80" alt="" />
              <div className="text-3xl font-bold text-white">
                Welcome to Grocedy
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RegisterForm;
