import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";

function BuyAGiftVoucher({
  setOpen,
  setOpenPin,
  setVoucherDetails,
  setVoucherSummaryItems,
}) {
  const [formValues, setFormValues] = useState({
    amount: "",
    reciepient_name: "",
    reciepient_email: "",
    reciepient_mobile: "",
    remark: "",
  });

  const handleChange = (event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      setFormValues((values) => ({ ...values, [name]: value }));
    } catch (err) {
      return err;
    }
  };

  const handleSubmit = () => {
    try {
      if (!formValues.amount || !formValues.reciepient_name) {
        return toast.error("Please fill required fields");
      }

      if (formValues.remark && !formValues.reciepient_email) {
        return toast.error("Please fill email field");
      }
      setVoucherDetails(formValues);
      setVoucherSummaryItems({});
      setOpenPin(true);
      setOpen(false);
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="px-2">
      <div className="flex max-w-lg flex-row items-center justify-between bg-[#FEFEFD]">
        <h2 className="text-lg font-semibold text-gray-700">
          Buy a Gift Voucher
        </h2>
        <div
          onClick={() => setOpen(false)}
          className="cursor-pointer rounded-full bg-olivine-500 p-1 text-white active:bg-olivine-400"
        >
          <IoClose className="h-5 w-5" />
        </div>
      </div>
      <div className="mt-2 rounded-lg border-[0.5px] border-gray-200 bg-white px-4 py-2 shadow-md">
        <form className="pb-6">
          <div className="">
            <label
              htmlFor="email"
              className="ml-3 text-xs font-medium text-gray-600"
            >
              Enter Amount*
            </label>
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  type="number"
                  name="amount"
                  value={formValues.amount}
                  onChange={handleChange}
                  placeholder=""
                  className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="email"
              className="ml-3 text-xs font-medium text-gray-600"
            >
              Recipient’s Name*
            </label>
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  type="text"
                  name="reciepient_name"
                  value={formValues.reciepient_name}
                  onChange={handleChange}
                  placeholder=""
                  className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="email"
              className="ml-3 text-xs font-medium text-gray-600"
            >
              Recipient’s Phone number*
            </label>
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  type="text"
                  name="reciepient_mobile"
                  value={formValues.reciepient_mobile}
                  onChange={handleChange}
                  placeholder=""
                  className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="email"
              className="ml-3 text-xs font-medium text-gray-600"
            >
              Recipient’s Email Address
            </label>
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <input
                  type="text"
                  name="reciepient_email"
                  value={formValues.reciepient_email}
                  onChange={handleChange}
                  placeholder=""
                  className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="email"
              className="ml-3 text-xs font-medium text-gray-600"
            >
              Message (Optional)
            </label>
            <div className="relative flex items-center justify-end rounded-md">
              <div className="flex-grow">
                <textarea
                  type="text"
                  name="remark"
                  value={formValues.remark}
                  onChange={handleChange}
                  placeholder=""
                  className="block w-full rounded-md border-gray-200 bg-white pl-4 pr-10 text-sm placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                />
              </div>
            </div>
          </div>
        </form>
        <div className="mt-4">
          <button
            disabled={
              formValues?.amount &&
              formValues?.reciepient_name &&
              formValues?.reciepient_mobile
                ? false
                : true
            }
            onClick={() => handleSubmit()}
            className="w-full transform cursor-pointer rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

export default BuyAGiftVoucher;
