import React from "react";

function SignedInHeaderItems({ Icon, title, clicked }) {
  return (
    <div
      onClick={clicked ? clicked : null}
      className="group flex cursor-pointer items-center space-x-1.5 text-base"
    >
      <div className="">{Icon}</div>
      <p className="">{title}</p>
    </div>
  );
}

export default SignedInHeaderItems;
