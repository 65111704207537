import React, { useContext, useEffect, useState } from "react";

import "./creatBasket.css";
import CreateBasketCard from "./CreateBasketCard";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import { useNavigate } from "react-router-dom";

function TopDeals(props) {
  let navigate = useNavigate();
  const rxjsStore = useContext(RxjsStoreContext);
  const { allProductList } = props;
  const [topList, setTopList] = useState([]);

  useEffect(() => {
    let newList = allProductList?.slice(4, 8);
    setTopList(newList);
  }, [allProductList]);

  const seeAllItems = () => {
    rxjsStore.setSectionList(topList);
    navigate(`/basket/create-basket/section`);
  };

  return (
    <section className="mt-4">
      <div className="flex items-center justify-between">
        <div className="">
          <div className="flex">
            <div className="text-lg font-bold text-gray-600">Top Deals</div>
          </div>
        </div>
        <div className="">
          <div
            className="group cursor-pointer font-semibold text-olivine-500"
            onClick={() => seeAllItems()}
          >
            <div> See All {topList?.length} items </div>
            <hr className="-mt-1 h-[2px] bg-olivine-500 opacity-0 group-hover:opacity-100" />
          </div>
        </div>
      </div>

      <div className="mt-6 grid gap-6 grid-auto-fit-sm ">
        {topList?.map((product, index) => {
          return (
            <CreateBasketCard product={product} index={index} key={index} />
          );
        })}

        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
}

export default TopDeals;
