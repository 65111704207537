import React, { useState, useContext } from "react";
import Logo from "../assets/logo.svg";
import { useNavigate, Link } from "react-router-dom";
import {
  emailVerification,
  emailVerificationResend,
} from "../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { verifyPhonenumber } from "../store/actions";
import { setAddNewPhonenumber } from "../store/slices/modalSlice";
import toast from "react-hot-toast";
import { authService } from "../services/authService";
import LogicContext from "../context/LogicContext";
import CircularProgress from "@mui/material/CircularProgress";

function EmailVerification() {
  let navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { isForgotPassword, isAddNewPhonenumber } = useSelector(
    (state) => state.modal
  );
  const { phoneNumber } = useSelector((state) => state.masterData);
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = (event) => {
    const pasted = event.clipboardData.getData("text/plain");
    setOtp(pasted.split("").slice(0, otp.length));
  };

  const submitOtpForVerification = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let payload;

    if (isAddNewPhonenumber) {
      if (!phoneNumber?.user_mobile_number) {
        setIsLoading(false);
        return toast.error("Your mobile number is invalid");
      }

      payload = {
        sql_ref_no: "9",
        user_id:
          authService.getCurrentUserID().toString() ||
          authService.getUnverifiedUserID().toString(),
        mobile_number: phoneNumber?.user_mobile_number,
        verification_code: otp.join(""),
        option: "2",
        country_code: "234",
      };

      return dispatch(verifyPhonenumber(payload))
        .unwrap()
        .then((res) => {
          if (res?.message_id === 1) {
            setIsLoading(false);
            setOtp([...otp.map((v) => "")]);
            dispatch(setAddNewPhonenumber(false));
            toast.success(res?.message_text);

            const sharing = localStorage.getItem("isShare");
            const gropool_id = localStorage.getItem("gropool_id");

            if (sharing && gropool_id) {
              localStorage.removeItem("isShare");
              localStorage.removeItem("gropool_id");
              return navigate(`/gropool/detail/${gropool_id}`);
            } else {
              navigate("/home/todo");
            }
          }
          if (res?.status === 403) {
            setIsLoading(false);
            return toast.error(res?.response?.message);
          }

          // return navigate("/home/todo");
        })
        .catch((err) => {
          setOtp("");
          navigate("/home");
          return err;
        });
    }

    payload = {
      sql_ref_no: "7",
      user_id:
        (await authService.getCurrentUserID()) ||
        (await authService.getUnverifiedUserID()),
      verification_code: otp.join(""),
      option: "1",
    };

    authService
      ?.emailVerification(payload)
      .then((response) => {
        setIsLoading(false);
        const filteredData = logicContext.httpResponseManager(response?.data);
        if (filteredData?.message_id == 1) {
          const tempToken = authService.getTokenUnVerified();
          authService.setToken(tempToken);
          toast.success("Success!");
          navigate("/home/todo");
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const handleResend = async () => {
    const payload = {
      sql_ref_no: "6",
      user_name:
        (await authService?.getUnverifiedUserEmail()) ||
        (await authService.getCurrentUserEmail()),
      option: "2",
    };
    setIsLoading(true);
    authService
      ?.emailVerification(payload)
      .then((response) => {
        setIsLoading(false);
        const filteredData = logicContext.httpResponseManager(response?.data);

        if (filteredData?.message_id == 1) {
          toast.success("Verification code has been sent to email");
        }
      })
      .catch((err) => setIsLoading(false));

    // dispatch(emailVerificationResend(payload))
    //   .unwrap()
    //   .then((res) => {
    //     toast.success("Verification code has been sent to email", {
    //       id: toastId,
    //     });
    //   })
    //   .catch((err) => {
    //     setOtp("");

    //     toast.error("Verification code could not be sent to email!", {
    //       id: toastId,
    //     });

    //     navigate("/login");
    //     return err;
    //   });
  };

  return (
    <div className="h-screen w-screen px-2 py-4 xsm:px-4 sm:px-8">
      <div className="">
        <Link to="/">
          <img
            src={Logo}
            className="aspect-auto h-7 w-8 cursor-pointer object-cover transition duration-300 hover:opacity-80 sm:h-11 sm:w-12"
            alt=""
          />
        </Link>
      </div>
      <div className="mt-8 flex items-center justify-center">
        <div className="w-full max-w-md rounded-xl bg-[#F6F5F5] px-3 py-6 ring-[1px] ring-gray-200 xsm:px-6 sm:px-8">
          <div>
            <div className="text-xl font-bold text-gray-600">
              {isAddNewPhonenumber
                ? "Verify Your Phone Number"
                : "Email Verification"}
            </div>
            <div className="mt-3 text-xs text-gray-500 sm:text-sm">
              {isAddNewPhonenumber
                ? "Please enter the code sent to your phone number"
                : `Please enter the verification code we sent to your email
                  address.`}
            </div>
          </div>
          <div className="mt-8">
            <form onSubmit={submitOtpForVerification}>
              <div className="relative mt-8 flex justify-center space-x-2 rounded-md">
                {otp &&
                  otp.length &&
                  otp?.map((data, index) => {
                    return (
                      <input
                        type="text"
                        maxLength={1}
                        className="block h-8 w-8 items-center justify-center rounded-md bg-white text-center text-[12px] focus:border-olivine-500 focus:ring-olivine-500 sm:h-10 sm:w-10 sm:text-lg"
                        name="opt"
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onPaste={handlePaste}
                        onFocus={(e) => e.target.select()}
                      />
                    );
                  })}
              </div>
              <button
                disabled={isLoading}
                className="mt-8 w-full rounded-md bg-olivine-500 py-3 text-sm font-semibold text-white transition-all duration-300 hover:bg-olivine-600"
              >
                {isLoading === true ? (
                  <CircularProgress size={20} color="inherit" />
                ) : isAddNewPhonenumber ? (
                  "VERIFY PHONE NUMBER"
                ) : (
                  "VERIFY EMAIL ADDRESS"
                )}
                {/* {isAddNewPhonenumber  ? "VERIFY PHONE NUMBER" : "VERIFY EMAIL ADDRESS"} */}
              </button>
            </form>
          </div>
          <div className="mt-4 text-sm">
            <div className=" text-gray-500">
              Didn’t receive a code?
              <span
                className="cursor-pointer px-3 font-semibold text-olivine-500"
                onClick={() => handleResend()}
              >
                RESEND CODE
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
