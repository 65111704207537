import React, { useRef, useContext, useEffect, useState } from "react";
import { debounce } from "lodash";
import "./creatBasket.css";
import toast from "react-hot-toast";
import YourFavourite from "./YourFavourite";
import TopDeals from "./TopDeals";
import AllItems from "./AllItems";
import BasketService from "../../../services/basketService";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import Handpick4You from "./Handpick4You";
import IsLoadingOne from "../../IsLoading_One";
import BasketFloatingPane from "./BasketFloatingPane";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import Carousel from "./Carousel";
import { authService } from "../../../services/authService";
import { HiChevronDoubleRight } from "react-icons/hi";
import { GoSearch } from "react-icons/go";
import { BiArrowBack } from "react-icons/bi";
import SearchPageEmpty from "./SearchPageEmpty";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { fetchGrocedyForAll } from "../../../store/actions";
import introJs from "intro.js";

function CreateBasketContent() {
  const dispatch = useDispatch();
  const rxjsStore = useContext(RxjsStoreContext);
  const [basketGroupList, setBasketGroupList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [allProductList, setAllProductList] = useState([]);
  const [fetchedProducts, setFectchedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState("");
  const [searchTextInput, setSearchTextInput] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [activeindex, setActiveIndex] = useState(0);
  const [prdId, setPrdId] = useState("");
  const [width, setWidth] = useState(0);
  const searchRef = React.createRef();
  const navigate = useNavigate();
  const carouselTwo = useRef();

  const customerId = authService.getCurrentUserCustomerId();

  const { grocedyForAll } = useSelector((state) => state.masterData);

  const isTour = localStorage.getItem("isTour_basket");

  useEffect(() => {
    localStorage.setItem("basket_type", "basket");
  }, []);

  useEffect(() => {
    if (isTour === "true") {
      const timeout = setTimeout(() => {
        startTour();
        localStorage.setItem("isTour_basket", "false"); // Persist in localStorage
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [isTour]);

  const startTour = () => {
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro:
            "Pick exactly what you need! Select multiple food items and build your perfect grocery list.",
        },
        {
          element: "#basket1",
          intro:
            "Remember, your total order must be at least ₦15,000.00 to proceed to checkout..",
        },
        {
          element: "#shop0",
          intro: "Shop by tapping items to add to your cart.",
        },
        {
          element: "#cart3",
          intro:
            "Finished shopping? Head to checkout, confirm your order, and have your groceries delivered!",
        },
        {
          element: "#basket4",
          intro: "Got it!",
        },
      ],
      tooltipClass: "customTooltip",
      highlightClass: "border-2 border-[#739a2e] rounded-lg shadow-xl",
      overlayOpacity: 0.5,
      showProgress: false, // Hides the progress bar
      showBullets: false,
    });
    intro.start();
  };

  useEffect(() => {
    setWidth(
      carouselTwo.current.scrollWidth - carouselTwo.current.offsetWidth - 100
    );
  }, []);

  useEffect(() => {
    if (!grocedyForAll) {
      dispatch(fetchGrocedyForAll(customerId ? customerId : "0"))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (grocedyForAll) {
      setFectchedProducts(grocedyForAll?.product_info);
    }
  }, [grocedyForAll, customerId]);

  useEffect(() => {
    if (fetchedProducts?.length) {
      const pubId = fetchedProducts
        ?.filter((fp) => fp?.product_id === "2")
        .pop();
      setPrdId(pubId?.product_id);
    }
  }, [fetchedProducts]);

  const scrollLeft = () => {
    document.getElementById("content").scrollLeft -= 200;
  };
  const scrollRight = () => {
    document.getElementById("content").scrollLeft += 200;
  };

  const style = {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  };

  useEffect(() => {
    if (authService.isLogin()) {
      rxjsStore.swapUserBasketId();
    }
  }, []);

  useEffect(() => {
    if (authService.isLogin() && prdId) {
      callApiForAuth();
    }
    rxjsStore.getAllBasketProductList().subscribe((allProduct) => {
      setAllProductList(allProduct);
      setSearchResult(allProduct);
    });

    rxjsStore.getBasketGroupList().subscribe((data) => {
      if (data?.length < 1) {
        callApi();
      } else {
        setBasketGroupList(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prdId]);

  function callApiForAuth() {
    setIsLoading(true);
    if (prdId) {
      BasketService.getBasketListAuth(prdId)
        .then((data, failed) => {
          if (failed) {
            // return toast.error("error getting products");
          }

          console.log("data", data);

          setIsLoading(false);
          const { item_group, items, delivery_charge } = data;
          if (item_group?.length) {
            rxjsStore.setBasketGroupList(item_group);
            rxjsStore.setAllBasketProductList(items);
            let charge = {
              item_id: delivery_charge[0]?.item_id,
              item_total: delivery_charge[0]?.item_rate,
              rate: delivery_charge[0]?.item_rate,
              quantity: 1,
              name: "Delivery charge",
            };
            rxjsStore.setDeliveryChargeBasket(charge);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          // toast.error("Error getting product");
        });
    }
  }

  function callApi() {
    setIsLoading(true);
    BasketService.getBasketList()
      .then((data, failed) => {
        if (failed) {
          return toast.error("error getting products");
        }

        setIsLoading(false);
        const { item_group, items, delivery_charge } = data;
        if (item_group?.length) {
          rxjsStore.setBasketGroupList(item_group);
          rxjsStore.setAllBasketProductList(items);

          let charge = {
            item_id: delivery_charge[0]?.item_id,
            item_total: delivery_charge[0]?.item_rate,
            rate: delivery_charge[0]?.item_rate,
            quantity: 1,
            name: "Delivery charge",
          };
          rxjsStore.setDeliveryChargeBasket(charge);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error getting product");
      });
  }

  function viewOrderHistory() {
    navigate("/basket/order-history");
  }

  const resetProductGroup = (groupId) => {
    setActiveIndex(groupId);
    const newList = rxjsStore.getProductByGroup(groupId);
    // setAllProductList(newList);
    setCurrentSection(groupId);
    setSearchResult(newList);
  };
  const debouncedSearch = React.useRef(
    debounce(async (event) => {
      processSearch(event);
    }, 1500)
  ).current;

  function processSearch(event) {
    const search = event.target.value;
    setSearchTextInput(search);

    setCurrentSection(search);

    if (search.length < 2) {
      setCurrentSection("");
      rxjsStore.getAllBasketProductList().subscribe((allProduct) => {
        setAllProductList(allProduct);
        setSearchResult(allProduct);
      });
      return;
    } else if (search.length > 2) {
      var tempResult = rxjsStore.handleSearchProduct(search);
      setSearchResult(tempResult);
    }
  }

  return (
    <>
      <section className="relative">
        <section>
          {isLoading ? (
            <div>
              <IsLoadingOne message="Getting products..." />
            </div>
          ) : (
            <section className="mx-auto mt-10 max-w-[1600px] px-4 sm:px-6 md:px-16">
              <div className="mb-5 xsm:flex xsm:items-center xsm:justify-between xsm:space-x-2 sm:space-x-4">
                <div className="flex items-center space-x-2">
                  <div className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
                    <BiArrowBack
                      onClick={() => navigate(-1)}
                      className="cursor h-5 w-5 text-gray-600"
                    />
                  </div>
                  <div className="hidden text-sm font-semibold text-gray-700 sm:text-base md:inline">
                    Pick your Basket
                  </div>
                </div>
                <div className="mt-2 max-w-[600px] flex-1 xsm:mt-0">
                  <div
                    className={`flex items-center rounded-lg border  border-gray-400 shadow-sm focus:border-olivine-500 ${
                      searchInputValue
                        ? "border-opacity-100"
                        : "border-opacity-60"
                    }
                      ${
                        searchInputValue
                          ? "ring-[1px] ring-olivine-500"
                          : "ring-0"
                      }
                    `}
                  >
                    <input
                      ref={searchRef}
                      onChange={(ev) => {
                        debouncedSearch(ev);
                        setSearchInputValue(ev.target.value);
                      }}
                      type="text"
                      placeholder="Quick search for anything"
                      className="flex-grow border-0 bg-transparent pl-5 text-base text-gray-600 outline-none ring-0 placeholder:text-sm placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0"
                    />
                    <div className="flex h-10 w-10 items-center justify-center rounded-r-lg bg-gray-100">
                      <GoSearch className="h-[18px] w-[18px] cursor-pointer rounded-full text-olivine-500" />
                    </div>
                  </div>
                </div>
                {/* <div className='-mt-[71px] xsm:mt-0 md:col-span-1 flex justify-end text-sm text-gray-500 font-semibold cursor-pointer hover:text-gray-700'><span className='md:hidden'>Cancel</span><span className='hidden md:inline'>Cancel Search</span></div> */}

                <div
                  onClick={() => viewOrderHistory()}
                  className=" -mt-[75px] flex cursor-pointer items-center justify-end text-olivine-500 hover:text-olivine-600 xsm:mt-0"
                >
                  <div className="hidden text-sm font-semibold sm:text-base md:inline-flex">
                    Order History
                  </div>
                  <div className="ml-1">
                    <HiChevronDoubleRight className="h-5 w-5" />
                  </div>
                </div>
              </div>
              <section>
                <div className="mt-20 flex space-x-1 text-xs xsm:mt-8 sm:mt-12 sm:text-sm">
                  <div className="font-bold text-gravel-500">Note:</div>
                  <div id="basket1" className="font-semibold text-gray-500">
                    To create your basket, you are required to place orders
                    above ₦15,000.00
                  </div>
                </div>
              </section>
              <section className="my-2 w-full">
                <motion.div
                  ref={carouselTwo}
                  whileTap={{ cursor: "grabbing" }}
                  className=" relative overflow-hidden"
                >
                  <div className="absolute -right-5 top-0 z-20 h-[70px] w-8 bg-white blur" />
                </motion.div>

                {/* DDDFDFDFDFF */}
                <div className="group relative overflow-hidden">
                  <div
                    id="content"
                    className="carousel flex items-center justify-start overflow-x-auto scroll-smooth px-16 py-4  scrollbar-hide"
                  >
                    {basketGroupList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => resetProductGroup(item?.group_id)}
                          className={`mr-2 min-w-fit rounded-md border-[1px] px-6 py-3 text-sm font-medium sm:mr-4 sm:px-12 sm:text-base ${
                            item?.group_id === activeindex
                              ? "border-white bg-olivine-500 text-white"
                              : "cursor-pointer border-olivine-500 border-opacity-50 bg-white text-olivine-500 hover:bg-olivine-200 hover:bg-opacity-60"
                          }`}
                        >
                          {item?.group_name}
                        </div>
                      );
                    })}
                  </div>
                  <div className="absolute -left-1 top-0 z-10 hidden h-[82px] w-14 bg-white blur-sm sm:inline" />
                  <div className="absolute -right-1 top-0 z-10 hidden h-[82px] w-14 bg-white blur-sm sm:inline" />
                  <button
                    onClick={scrollLeft}
                    className="absolute left-0 top-5 z-30 rounded-full bg-gray-100 p-2 shadow-sm shadow-gray-400 hover:bg-gray-200 active:bg-gray-100 sm:ml-1 sm:hidden sm:group-hover:flex"
                  >
                    <FiChevronLeft className="h-6 w-6 text-gray-600" />
                  </button>
                  <button
                    onClick={scrollRight}
                    className="absolute right-0 top-5 z-30 rounded-full bg-gray-100 p-2 shadow-sm shadow-gray-400 hover:bg-gray-200 active:bg-gray-100 sm:mr-1 sm:hidden sm:group-hover:flex"
                  >
                    <FiChevronRight className="h-6 w-6 text-gray-600" />
                  </button>
                </div>
              </section>
              {/* ENDDDDDDDD */}

              {/* CURRENCY */}
              {/* <div className="mt-8 flex cursor-pointer items-center font-medium">
                <div className="rounded-l-md bg-olivine-500 px-5 py-3 text-white">
                  NGN
                </div>
                <div className="rounded-r-md bg-caper-500 px-5 py-3 text-gravel-500">
                  USD
                </div>
              </div> */}

              {/* END CURRENCY */}
              {!currentSection && (
                <>
                  <section className="mt-4">
                    <Handpick4You allProductList={allProductList} />
                  </section>

                  <section className="mt-10">
                    {authService.isLogin() && (
                      <YourFavourite
                        allProductList={allProductList}
                      ></YourFavourite>
                    )}
                  </section>
                  <div>
                    <TopDeals allProductList={allProductList} />
                  </div>
                </>
              )}
              <div>
                {searchResult?.length ? (
                  <AllItems allProductList={searchResult} />
                ) : (
                  <SearchPageEmpty searchResult={searchResult} />
                )}
              </div>
            </section>
          )}
        </section>

        <section className="flex justify-center bg-red-900">
          <BasketFloatingPane style={style} />
        </section>
      </section>
    </>
  );
}

export default CreateBasketContent;
